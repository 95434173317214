import React from 'react'
import { InputText,
         Button,
         Checkbox,
         Radio,
         Toggle,
         Select,
        Textarea } from 'components/ui'
import { IconPlus, IconCheck, IconCross } from 'components/icons' 

const options = [
  { value: 'moscow', label: 'Москва' },
  { value: 'petersburg', label: 'Санкт-Петербург' },
  { value: 'london', label: 'Лондон' },
];

const UiKitPage = () => (
  <div style={{
    padding: '20px'
  }}>
    <h1>Превратим субботу в ШАББАТ!</h1>
    <br />
    <h2>Превратим субботу в ШАББАТ!</h2>
    <br />
    <h3>Превратим субботу в ШАББАТ!</h3>
    <br />
    <h4>Превратим субботу в ШАББАТ!</h4>
    <br />
    <h5>Превратим субботу в ШАББАТ!</h5>
    <br />
    <p>
      Тут одно-два предложения, кратко описывающие проект.<br />
      Тут одно-два предложения, кратко описывающие проект.
    </p>

    <p><br /></p>


    <div style={{
      width: '350px'
    }}>
      <InputText placeholder="ФИО" />
      <br />
      <InputText
        placeholder="Адрес"
        error={'Пожалуйста, введите корректный адрес'}
      />
      <br />
      <InputText placeholder="ФИО" disabled />
    </div>
    <p><br /></p>
    <div style={{width: '255px'}}>
      <Button text={'Подписаться'} />
      <br />
      <Button text={'Подписаться'} disabled />
      <br />
      <Button text={'Подписаться'} ghost />
      <br />
      <Button text={'Подписаться'} ghost disabled />
    </div>

    <div style={{margin: '25px 0'}}>
      <Checkbox id="ui-kit-checkbox"
                text="Подписаться на новости" />
    </div>
    <div style={{margin: '25px 0'}}>
      <Checkbox id="ui-kit-checkbox"
                text="Подписаться на новости"
                error={"Error"}  />
    </div>

    <div style={{marginBottom: '25px'}}>
      <Radio id="ui-kit-radio" text="Подписаться на новости" />
    </div>
    <div style={{marginBottom: '25px'}}>
      <Radio id="ui-kit-radio"
             text="Подписаться на новости"
             error={"Error"} />
    </div>

    <div style={{
      width: '200px',
      marginBottom: '25px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <IconPlus />
      <IconCheck />
      <IconCross />
    </div>

    <div>
      <Toggle
        values={{
          left: {
            value: '0',
            label: 'Test 1'
          },
          right: {
            value: '1',
            label: 'Test 2'
          }
        }}
        name={'test'}
        onChange={e => {}}
      />
    </div>

    <div style={{width: '445px', margin: '25px 0'}}>
      <Select placeholder="Ваш город"
              options={options}
              label="Выберите город" />
    </div>
    <div style={{width: '445px', margin: '25px 0'}}>
      <Select placeholder="Ваш город"
              options={options}
              error={'Выберите город!'} />
    </div>

    <div style={{width: '445px', margin: '25px 0'}}>
      <Textarea placeholder="Введите текст"
                error={'Это поле обязательно для заполнения'}
                id="ui-kit-textarea" />
    </div>

  </div>
)

export default UiKitPage
